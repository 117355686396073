import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const BreadcrumbComponent = ({ breadcrumbs }) => {
  return (
    <Breadcrumb
      spacing={["1px", "8px", "8px", "8px", "8px", "8px"]}
      fontSize={["10px", "10px", "13px", "15px", "15px", "15px"]}
      mb="20px"
      color="primary"
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {breadcrumbs &&
        breadcrumbs.map((data) => (
          <BreadcrumbItem>
            <Link to={data?.link}>{data?.name}</Link>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
