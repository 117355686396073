import {
  Box,
  Flex,
  Image,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";

import ScrollToTop from "../../utils/scrollToTop";

function PatientLayout({ children, index }) {
  const steps = [
    {
      title: "Personal Info",
      description:
        "Double-check your personal details to ensure we can reach you if necessary before proceeding to the next form",
    },
    {
      title: "Medical Information",
      description:
        " If uncertain about your medical information, you can leave this section partially blank, but be sure to update it later.",
    },

    {
      title: "Emergency Contact",
      description:
        "Ensure you provide accurate details for your emergency contact, as that's the person we'll contact if needed",
    },
  ];

  const { activeStep } = useSteps({
    index: index,
    count: steps.length,
  });

  return (
    <Box p="10px" minH="100vh">
      <ScrollToTop />
      <Flex>
        <Image
          h="87.311px"
          w="92px"
          src="https://i.ibb.co/dJCJZT5/ANPA-5-1776451360-1-1.png"
        />
        <Box
          display="flex"
          h="40px"
          mt="20px"
          ml="10px"
          bg="primary"
          w="99%"
        ></Box>
      </Flex>
      <Flex>
        <Box
          display={["none", "none", "none", "flex", "flex", "flex"]}
          w="20%"
          mt="100px"
          justifyContent="center"
        >
          <Stepper
            mt="-50px"
            index={activeStep}
            orientation="vertical"
            height="70vh"
            gap="0"
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription maxW="200px">
                    {step.description}
                  </StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box w={["100%", "100%", "100%", "80%", "80%", "80%"]}>{children}</Box>
      </Flex>
    </Box>
  );
}

export default PatientLayout;
