import axios from "axios";
import { BaseURL } from "./url";

//Auth

export const superadminRegistration = async (formData) => {
  return axios.post(`${BaseURL}/superadmin-registration`, formData);
};
export const superadminRegistration2 = async (formData) => {
  return axios.post(`${BaseURL}/superadmin-registration2`, formData);
};
export const patientRegistration = async (formData) => {
  return axios.post(`${BaseURL}/patient-registration`, formData);
};
export const patientUpdatebio = async (data) => {
  return axios.post(`${BaseURL}/update-patient-bio/${data?.idz}`, data);
};
export const patientUpdateAddress = async (data) => {
  return axios.post(`${BaseURL}/update-patient-address/${data?.idz}`, data);
};
export const patientUpdateSurgery = async (data) => {
  return axios.post(`${BaseURL}/update-surgery/${data?.idz}`, data);
};
export const patientUpdateEmerg = async (data) => {
  return axios.post(
    `${BaseURL}/update-patient-emergency/${data?.idz}`,
    data?.data
  );
};
export const patientRegistration2 = async (allData) => {
  return axios.post(`${BaseURL}/patient-registration2`, allData);
};
export const createMission = async (formData) => {
  return axios.post(`${BaseURL}/mission-registration`, formData);
};
export const editMission = async (data) => {
  return axios.post(`${BaseURL}/update-mission/${data?.id}`, data?.data);
};
export const sendMessage = async (formData) => {
  return axios.post(`${BaseURL}/new-message`, formData);
};
export const loginUser = async (data) => {
  return axios.post(`${BaseURL}/login-anpa`, data);
};
export const newCpd = async (data) => {
  return axios.post(`${BaseURL}/new-cpd`, data);
};
export const updatePassword = async (datas) => {
  return axios.post(
    `${BaseURL}/update-present-password/${datas?.email}`,
    datas
  );
};
export const assignPatient = async (datas) => {
  return axios.post(`${BaseURL}/assign-patient/${datas?.id}`, datas);
};
export const assignPatient2 = async (datas) => {
  return axios.post(`${BaseURL}/assign-surgeon/${datas?.id}`, datas);
};
export const reassignPatient = async (datas) => {
  return axios.post(
    `${BaseURL}/reassign-patient/${datas?.id}/${datas?.id2}`,
    datas
  );
};
export const logOutApi = async () => {
  return axios.post(`${BaseURL}/log-out-anpa`);
};
export const createSchedule = async (data) => {
  return axios.post(`${BaseURL}/create-schedule`, data);
};
export const editSchedule = async (data) => {
  return axios.post(`${BaseURL}/edit-schedules-detail/${data?.id}`, data);
};
export const updatePatientLabTest = async (dataForMutate) => {
  return axios.post(
    `${BaseURL}/update-identified-patient-lab-test?con_id=${dataForMutate?.id}`,
    dataForMutate
  );
};
export const startConsultation = async (data) => {
  return axios.post(`${BaseURL}/start-consultation2/${data?.id}`, data);
};
export const patientEndoscopy = async (data) => {
  return axios.post(`${BaseURL}/patient-endoscopy/${data?.id}`, data);
};
export const anesthesiaIntraoperative = async (data) => {
  return axios.post(`${BaseURL}/anesthesia-intraoperative/${data?.id}`, data);
};
export const anesthesiaPostOp = async (data) => {
  return axios.post(`${BaseURL}/anesthesia-post-op/${data?.id}`, data);
};
export const anesthesiaPreOp = async (data) => {
  return axios.post(`${BaseURL}/anesthesia-pre-op/${data?.id}`, data);
};
export const anesthesiaEvaluation = async (data) => {
  return axios.post(`${BaseURL}/anesthesia-evaluation/${data?.id}`, data);
};
export const startObservation = async (data) => {
  return axios.post(`${BaseURL}/conduct-observation/${data?.id}`, data);
};
export const addNewVitials = async (data) => {
  return axios.post(`${BaseURL}/new-vitals/${data?.id}`, data);
};
export const infectiousRegistration = async (data) => {
  return axios.post(`${BaseURL}/infectious-registration/${data?.id}`, data);
};
export const psychiatryRegistration = async (data) => {
  return axios.post(`${BaseURL}/psychiatry-registration/${data?.id}`, data);
};
export const surgeryRegistration = async (formData) => {
  return axios.post(
    `${BaseURL}/surgery-registration/${formData?.id}`,
    formData?.data
  );
};
export const getSpecialization = async () => {
  return axios.get(`${BaseURL}/get-specialization`);
};
export const getSpecialization2 = async (age) => {
  return axios.get(`${BaseURL}/get-specialization2?age=${age}`);
};
export const getMessage = async (searchData) => {
  return axios.get(
    `${BaseURL}/get-messages?search=${searchData?.search}&patient_id=${searchData?.id}`
  );
};
export const getUsertype = async () => {
  return axios.get(`${BaseURL}/get-usertype`);
};
export const getDepartments = async () => {
  return axios.get(`${BaseURL}/get-departments`);
};
export const getDoctors = async (specialValue) => {
  return axios.get(`${BaseURL}/get-doctors/${specialValue}`);
};
export const getAllUser = async () => {
  return axios.get(`${BaseURL}/get-all-users`);
};
export const getActiveUser = async () => {
  return axios.get(`${BaseURL}/get-all-users2`);
};
export const getPatient = async (value) => {
  return axios.get(`${BaseURL}/get-patients?search=${value}`);
};
export const getPatientAnesthesia = async (value) => {
  return axios.get(`${BaseURL}/get-patients-for-anesthesia?search=${value}`);
};
export const getDispensing = async () => {
  return axios.get(`${BaseURL}/get-patients-prescription`);
};

export const geUsertDetails = async (id) => {
  return axios.get(`${BaseURL}/get-user-details/${id}`);
};
export const getPatienttDetails = async (id) => {
  return axios.get(`${BaseURL}/get-patient-details/${id}`);
};
export const getMissionDetails = async (id) => {
  return axios.get(`${BaseURL}/get-mission/${id}`);
};
export const identifiedPatientLab = async (id) => {
  return axios.get(`${BaseURL}/identified-patient-lab-test?con_id=${id}`);
};
export const getViewTestSample = async (currentView) => {
  return axios.get(`${BaseURL}/view-test-sample?con_id=${currentView}`);
};
export const getSpecificLabResult = async (id) => {
  return axios.get(`${BaseURL}/specific-lab-result?con_id=${id}`);
};
export const getDrugsDetails = async (id) => {
  return axios.get(`${BaseURL}/get-drug-details?drug_id=${id}`);
};
export const deleteSubstitution = async (value) => {
  return axios.post(`${BaseURL}/delete-drug-substitution/${value}`);
};
export const deleteAllergy = async (value) => {
  return axios.post(`${BaseURL}/delete-allergy-drugs/${value}`);
};
export const deleteChiefComplaint = async (value) => {
  return axios.post(`${BaseURL}/delete-chief-complaint-drugs/${value}`);
};
export const deleteMembersFromMission = async (value) => {
  return axios.post(`${BaseURL}/delete-mission-member/${value}`);
};
export const deleteMissionLocation = async (value) => {
  return axios.post(`${BaseURL}/delete-mission-location/${value}`);
};
export const deleteDiagnosis = async (value) => {
  return axios.post(`${BaseURL}/delete-diagnosis-drugs/${value}`);
};
export const grantSuperAdmin = async (value) => {
  return axios.post(`${BaseURL}/grant-superadmin/${value}`);
};
export const getAllDoctors = async (sendSelectedDoc) => {
  return axios.get(
    `${BaseURL}/get-all-doctors?search_parameter=${sendSelectedDoc?.search}&new_members=${sendSelectedDoc?.docs}`
  );
};
export const getSelectlDoctors = async () => {
  return axios.get(`${BaseURL}/get-doctor-select`);
};
export const getInventory = async () => {
  return axios.get(`${BaseURL}/get-inventory`);
};
export const getAllMissions = async () => {
  return axios.get(`${BaseURL}/get-all-missions`);
};
export const getlMissionsSelect = async () => {
  return axios.get(`${BaseURL}/get-missions`);
};
export const getAllSchedules = async () => {
  return axios.get(`${BaseURL}/get-all-schedules`);
};
export const getPatientsForLab = async () => {
  return axios.get(`${BaseURL}/patients-for-lab-test`);
};
export const getLabResult = async () => {
  return axios.get(`${BaseURL}/patients-lab-result`);
};
export const getPatientsSampleCollected = async () => {
  return axios.get(`${BaseURL}/patients-sample-collected`);
};

export const getAllergies = async (data) => {
  return axios.get(
    `${BaseURL}/get-allergies?search=${data?.search}&p_id=${data?.id}`
  );
};
export const getLabTestSubcategory = async (testList1) => {
  return axios.get(`${BaseURL}/get-lab-test-subcategory?id=${testList1}`);
};

export const getLabTest = async (senderTest) => {
  return axios.get(
    `${BaseURL}/get-lab-test?search=${senderTest?.search}&id=${senderTest?.id}`
  );
};
export const getChiefComplaint = async (chiefComlain) => {
  return axios.get(`${BaseURL}/get-chief-complaint?search=${chiefComlain}`);
};
export const getObjective = async (chiefComlain) => {
  return axios.get(`${BaseURL}/get-objectives?search=${chiefComlain}`);
};
export const getDocSchedules = async (id) => {
  return axios.get(`${BaseURL}/get-schedules-detail/${id}`);
};
export const getDiagnosis = async (diagonsis) => {
  return axios.get(`${BaseURL}/get-diagnosis?search=${diagonsis}`);
};
export const getAllDrugs = async (meds) => {
  return axios.get(`${BaseURL}/select-drugs?search=${meds}`);
};
export const getDrugs = async (sender) => {
  return axios.get(
    `${BaseURL}/get-drugs/${sender?.medsLocal}/${sender?.allergiesSelected}?search=${sender?.search}`
  );
};
export const getDrugs2 = async (search) => {
  return axios.get(`${BaseURL}/get-drugs2?search=${search}`);
};
export const endConsultation = async (data) => {
  return axios.post(
    `${BaseURL}/end-consultation/${data?.id}/${data?.consultation_id}`,
    data
  );
};
export const submtMst = async (data) => {
  return axios.post(`${BaseURL}/new-mst/${data?.id}`, data);
};
export const addCommentPhama = async (data) => {
  return axios.post(
    `${BaseURL}/new-patient-drug-comment/${data?.doc_id}/${data?.id}`,
    data
  );
};
export const disChargePatient = async (data) => {
  return axios.post(`${BaseURL}/discharge-patient/${data?.id}`, data);
};
export const getPatientConsultationDetails = async (id) => {
  return axios.get(`${BaseURL}/patient_medical_info/${id}`);
};
export const getFilledPatientData = async (id) => {
  return axios.get(`${BaseURL}/get-consultation/${id}`);
};
export const surgeryReportPostOp = async (id) => {
  return axios.get(`${BaseURL}/view-surgery-post_op_list?sur_id=${id}`);
};
export const surgeryReportNote = async (id) => {
  return axios.get(`${BaseURL}/view-surgery-note?sur_id=${id}`);
};
export const surgeryPostReport = async (id) => {
  return axios.get(`${BaseURL}/view-surgery-report?sur_id=${id}`);
};
export const getAddmissionDrugs = async (id) => {
  return axios.get(`${BaseURL}/get-drugs-for-admission/${id}`);
};
export const getPatientConsultationDetails2 = async (id) => {
  return axios.get(`${BaseURL}/patient_medical_info2/${id}`);
};
export const getObservationDetails = async (id) => {
  return axios.get(`${BaseURL}/get-observation-details/${id}`);
};
export const addTestResult = async (sendData) => {
  return axios.post(
    `${BaseURL}/add-test-result?id=${sendData?.id}`,
    sendData?.data
  );
};
export const initiateAdmission = async (data) => {
  return axios.post(`${BaseURL}/initiate-admission/${data?.id}`, data);
};
export const updateUserRole = async (data) => {
  return axios.post(`${BaseURL}/update-user-role`);
};
export const updateAllUserRole = async (data) => {
  return axios.post(`${BaseURL}/update-usertype`, data);
};
export const updateDepartment = async (data) => {
  return axios.post(`${BaseURL}/update-depatment`, data);
};
export const completePrescription = async (id) => {
  return axios.post(`${BaseURL}/complete-prescription/${id}`);
};
export const surgeryOperativePost = async (data) => {
  return axios.post(`${BaseURL}/surgery-operative/${data?.id}`, data?.data);
};
export const endoPreOp = async (data) => {
  return axios.post(`${BaseURL}./endo-pre-op/${data?.id}`, data);
};
export const surgeryPostOp = async (data) => {
  return axios.post(`${BaseURL}/surgery-post-op/${data?.id}`, data?.data);
};
export const surgeryPreOp = async (data) => {
  return axios.post(`${BaseURL}/surgery-pre-op/${data?.id}`, data?.data);
};
export const surgeryPreOpNurse = async (data) => {
  return axios.post(`${BaseURL}/patient-nurse-pre-checklist/${data?.id}`, data);
};
export const endoRegistration = async (data) => {
  return axios.post(`${BaseURL}/endo-registration/${data?.id}`, data?.data);
};
export const surgeryMainPost = async (data) => {
  return axios.post(`${BaseURL}/surgery/${data?.id}`, data);
};
export const endoIntraOperative = async (data) => {
  return axios.post(`${BaseURL}/endo-intra-operative/${data?.id}`, data);
};
export const intraSurgery1 = async (data) => {
  return axios.post(`${BaseURL}/intra-surgery1/${data?.id}`, data);
};
export const intraSurgery2 = async (data) => {
  return axios.post(`${BaseURL}/intra-surgery2/${data?.id}`, data);
};
export const intraSurgery3 = async (data) => {
  return axios.post(`${BaseURL}/intra-surgery3/${data?.id}`, data);
};
export const surgeryDischargePost = async (data) => {
  return axios.post(`${BaseURL}/surgery-discharge/${data?.id}`, data);
};
export const getMsiHistory = async (id) => {
  return axios.get(`${BaseURL}/get-mst-history?patient_id=${id}`);
};
export const getVisitSummary = async (id) => {
  return axios.get(`${BaseURL}/get-visit-history/${id}`);
};
export const getVitalsSummary = async (id) => {
  return axios.get(`${BaseURL}/get-vitals-summary/${id}`);
};
export const getLabTestHistory = async (id) => {
  return axios.get(`${BaseURL}/get-lab-test-history/${id}`);
};
export const getMedicationHistory = async (id) => {
  return axios.get(`${BaseURL}/get-medical-history/${id}`);
};
export const getDisableSchedules = async (doc) => {
  return axios.get(`${BaseURL}/get-schdeule-date-select/${doc}`);
};
export const getLocationAvalible = async (mission_location) => {
  return axios.get(`${BaseURL}/get-appointment-date/${mission_location}`);
};
export const getPatientEdit = async (id) => {
  return axios.get(`${BaseURL}/get-patient-information?user_id=${id}`);
};
export const getSpecificMissionLocation = async () => {
  return axios.get(`${BaseURL}/get-mission-location`);
};
export const getSugeryType = async () => {
  return axios.get(`${BaseURL}/get-surgery-type`);
};
export const getPatientConsultation = async (value) => {
  return axios.get(
    `${BaseURL}/get-patients-for-consultation?search=${value?.search}&status=${value?.status}`
  );
};
export const getPatientAdmission = async (value) => {
  return axios.get(`${BaseURL}/patients-for-admission`);
};
export const getPatientSurgery = async (value) => {
  return axios.get(`${BaseURL}/get-patients-for-surgery`);
};
export const getPatientSurgeryNurse = async (value) => {
  return axios.get(`${BaseURL}/get-patients-for-surgery-nurse`);
};
export const getPatientConsultationNurse = async (value) => {
  return axios.get(
    `${BaseURL}/get-patients-for-consultation-nurse?search=${value}`
  );
};
export const getTrainings = async (value) => {
  return axios.get(`${BaseURL}/get-trainings?date=${value}`);
};
export const getPrescriptionDetails = async (id) => {
  return axios.get(`${BaseURL}/prescription-details?consultation_id=${id}`);
};
export const getObservationHistory = async (id) => {
  return axios.get(`${BaseURL}/get-observation-history/${id}`);
};
export const commentFromPharmacist = async (id) => {
  return axios.get(`${BaseURL}/comment-from-pharmacist?comment_id=${id}`);
};
export const deleteMedication = async (id) => {
  return axios.post(`${BaseURL}/delete-medication?comment_id=${id}`);
};
export const updateMedication = async (data) => {
  return axios.post(
    `${BaseURL}/update-medication?comment_id=${data?.id}`,
    data
  );
};
export const forgotPass = async (data) => {
  return axios.post(`${BaseURL}/forget-password`, data);
};
export const addDrugs = async (minData) => {
  return axios.post(`${BaseURL}/add-drugs`, minData);
};
export const editDrugs = async (minData) => {
  return axios.post(`${BaseURL}/update-drugs/${minData?.id}`, minData);
};

export const generateRequestCode = async (data) => {
  return axios.post(`${BaseURL}/resend-token/${data}`);
};
export const veriflyeRequestCode = async (data) => {
  return axios.post(`${BaseURL}/verify/${data?.email}`, { secret: data?.code });
};
export const getTokenEmail = async () => {
  return axios.get(`${BaseURL}/get-token-email`);
};
export const changePassword = async (data) => {
  return axios.post(`${BaseURL}/update-password/${data?.email}`, {
    password: data?.password,
  });
};
