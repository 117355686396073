import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./pagenation";
import { FormInput } from "./inputs";
import { Spinner } from "react-bootstrap";
import { addTestResult, initiateAdmission } from "../utils/fetch";
import { useMutation } from "react-query";

function AdmissionTable({ data, isLoading, isError, pagenations }) {
  const navgate = useNavigate();
  const toast = useToast();

  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(9);
  const [itemOffset, setItemOffset] = useState(0);
  const [ward, setWard] = useState("");
  const [changeValue, setChangeValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  console.log(pagenations);
  const { mutate, isLoading: subbmitLoader } = useMutation(initiateAdmission, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: `Ward Successfully added`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        window.location.reload();
      } else if (data?.data?.status === 401) {
        toast({
          position: "top-right",
          description: `Please Login`,
          status: "error",
          duration: 9000,
        });
        navgate("/");
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;

    setItemOffset(newOffset);
  };
  const handleSubmit = (item) => {
    if (ward) {
      const sendData = {
        id: item,
        ward: ward,
      };
      console.log(sendData);
      mutate(sendData);
    } else {
      toast({
        position: "top-right",
        description: `Pls input ward`,
        status: "error",
        duration: 9000,
      });
    }
  };
  const breakText = (text, maxLength) => {
    const regex = new RegExp(`.{1,${maxLength}}`, "g");
    return text.match(regex) || [];
  };

  return (
    <Box>
      <TableContainer mt="20px">
        <Table size="sm">
          <Thead bg="#E9E9E9">
            <Tr>
              <Th>Mission id</Th>
              <Th>Patient id</Th>
              <Th>doctor </Th>
              <Th>Patient</Th>
              <Th>Date </Th>
              {pagenations === 0 && (
                <>
                  <Th>comment </Th>
                  <Th>Ward </Th>
                </>
              )}
              {pagenations === 1 && (
                <>
                  <Th>Reason for Admission </Th>
                  <Th>Admitting nurse </Th>
                  <Th>status </Th>
                  <Th>Ward </Th>
                </>
              )}
            </Tr>
          </Thead>
          {isLoading || isError ? (
            <Tbody>
              {[9, 8, 9, 9, 0, 9, 9]?.map((itemds) => (
                <Tr>
                  {[9, 8, 9, 9, 0, 0, 9]?.map((itemds) => (
                    <Td>
                      <Skeleton h="30px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems &&
                currentItems?.map((item) => (
                  <Tr
                    cursor="pointer"
                    bg="white"
                    onClick={() => {
                      if (pagenations === 1) {
                        navgate(`/admission-obeservation/${item?.id}`);
                      }
                    }}
                  >
                    <Td>{item?.mission_id}</Td>
                    <Td>{item?.patient_id}</Td>
                    <Td>{item?.patient}</Td>
                    <Td>{item?.doctor}</Td>
                    <Td>{item?.date}</Td>
                    {pagenations === 0 && (
                      <>
                        <Td>
                          {item?.comment.length > 100
                            ? breakText(item?.comment, 100).map(
                                (chunk, index) => <p key={index}>{chunk}</p>
                              )
                            : item?.comment}
                          <Text>
                            Admit under OBGYN Transfuse PRBC 1 unit over 4hours
                            twice daily for a total of 4 units. Repeat Hgb 24h
                            after last unit Plan for myeomectomy if able to
                            raise Hgb level to 10g and have 4 units on hold for
                            surrgery Give Ferous sulfate 325mg twice daily for
                            the next 6 mo Vitamin C 500mg daily with iron to
                            increase absorption
                          </Text>
                        </Td>
                        <Td>
                          <Flex>
                            <Box mt="16px" w="100px" minW="100px">
                              <FormInput
                                onChange={(e) => {
                                  setChangeValue(item?.id);
                                  setWard(e.target.value);
                                }}
                                placeholder="Ward"
                              />{" "}
                            </Box>

                            {changeValue === item?.id && (
                              <Button
                                isDisabled={subbmitLoader}
                                variant="primary"
                                ml="20px"
                                mt="15px"
                                onClick={() => handleSubmit(item?.id)}
                              >
                                {subbmitLoader ? (
                                  <Spinner
                                    color="primary"
                                    thickness="4px"
                                    size="lg"
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            )}
                          </Flex>
                        </Td>
                      </>
                    )}
                    {pagenations === 1 && (
                      <>
                        <Td>{item?.reason_for_admission}</Td>
                        <Td>{item?.nurse}</Td>
                        <Td>{item?.status}</Td>
                        <Td>{item?.ward}</Td>
                      </>
                    )}
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
        {data?.length <= 0 && (
          <Heading mt="150px" textAlign="center">
            {pagenations === 2
              ? "No Discharged Patients"
              : pagenations === 1
              ? "No Admitted Patient"
              : "No Patient for admission"}
          </Heading>
        )}
      </TableContainer>
      <Box position="absolute" bottom="0" right="10">
        <Pagination
          pageCount={isNaN(pageCount) ? 0 : pageCount}
          handlePageClick={handlePageClick}
        />
      </Box>
    </Box>
  );
}

export default AdmissionTable;
