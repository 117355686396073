import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import React, { Suspense } from "react";
import { Spinner } from "@chakra-ui/react";
function App() {
  localStorage.setItem("chakra-ui-color-mode", "light");

  return (
    <Suspense fallback={<Spinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
