import {
  Box,
  Heading,
  RadioGroup,
  Radio,
  useToast,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormInput, SelectInput } from "../components/inputs";
import { getPatientSurgery, getTrainings } from "../utils/fetch";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import AdmissionTable from "../components/admissionTable";
import SurgeryTable from "../components/surgeryTable";
import AttendacneTable from "../components/attendTable";

function AttendanceEvent() {
  const navgate = useNavigate();
  const toast = useToast();
  const [value, setValue] = useState("");

  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, isError } = useQuery(
    ["getTrainings", searchValue],
    () => getTrainings(searchValue),
    {
      refetchInterval: 1000,
    }
  );
  console.log(data);

  const [pagenations, setPagenation] = useState(0);
  const [feedData, setFeedData] = useState(data?.data?.ACLS);
  console.log(data);
  if (data?.data?.status === 401) {
    toast({
      position: "top-right",
      description: `Please login`,
      status: "error",
      duration: 9000,
    });
    navgate("/");
  }

  useEffect(() => {
    if (pagenations === 0) {
      setFeedData(data?.data?.ACLS);
    } else if (pagenations === 1) {
      setFeedData(data?.data?.Use_ECH);
    }
    // eslint-disable-next-line
  }, [pagenations, data]);
  const datas = [
    { name: "2024-04-06", value: "2024-04-06" },
    { name: "2024-04-09", value: "2024-04-09" },
    { name: "2024-04-10", value: "2024-04-10" },
    { name: "2024-04-11", value: "2024-04-11" },
  ];

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        w="18%"
        boxShadow="0px 2.76151px 2.76151px 0px rgba(0, 0, 0, 0.25)"
        bg="white"
        borderRadius="18px"
        h="auto"
        display={["none", "none", "none", "flex", "flex", "flex"]}
      >
        <Box w="100%">
          <Heading size="sm" p="10px">
            FILTER
          </Heading>
          <hr style={{ border: "1px solid  #A1A3A5" }}></hr>
          <Box p="15px">
            <SelectInput
              onChange={(e) => setSearchValue(e.target.value)}
              options={datas}
              placeholders="Pick Date"
              size="sm"
            />
          </Box>
        </Box>
      </Box>

      <Box
        w={["100%", "100%", "100%", "80%", "80%", "80%"]}
        boxShadow="0px 2.76151px 2.76151px 0px rgba(0, 0, 0, 0.25)"
        borderRadius="18px"
        bg="white"
      >
        <Heading size="md" pt="30px" pl="20px">
          Attendance
        </Heading>

        <Tabs colorScheme="green" onChange={(index) => setPagenation(index)}>
          <TabList>
            <Tab>ACLS</Tab>
            <Tab>Use ECH</Tab>
          </TabList>

          <TabPanels>
            {[0, 2].map((data) => (
              <TabPanel>
                <AttendacneTable
                  pagenations={pagenations}
                  data={feedData}
                  isLoading={isLoading}
                  isError={isError}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default AttendanceEvent;
