import {
  Box,
  Button,
  Image,
  Spinner,
  Text,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { FormInput, SelectInput } from "../../components/inputs";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { newCpd } from "../../utils/fetch";
import { titleData } from "../../components/data";
const Events = () => {
  const toast = useToast();
  const navgate = useNavigate();
  const [title, setTitle] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [lastname, setLastname] = useState();
  const [firstname, setFirstname] = useState();
  const [discipline, setdiscipline] = useState();
  const [organisation, setorganisation] = useState();
  const { mutate, isLoading: submitLoader } = useMutation(newCpd, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: `Action Successful`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navgate("/success3");
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });
  const subbmitHandler = (e) => {
    e.preventDefault();

    const data = {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      email: email,
      discipline: discipline,
      organisation: organisation,
      title: title,
      topic: "Advanced Cadiovascular Life Support (ACLS) Day 1 ",
    };
    console.log(data);
    mutate(data);
  };
  return (
    <form onSubmit={subbmitHandler}>
      <Box display="flex" justifyContent="space-between">
        {" "}
        <Box
          display={["none", "none", "none", "flex", "flex", "flex"]}
          w="50%"
          height="100vh"
        >
          <Image w="100%" src="https://i.ibb.co/y4FVH2j/Frame-9088-1.png" />
        </Box>
        <Box p="30px" w={["100%", "100%", "100%", "50%", "50%", "50%"]}>
          <Box display={["flex", "flex", "flex", "none", "none", "none"]}>
            <Image w="100%" src="https://i.ibb.co/y4FVH2j/Frame-9088-1.png" />
          </Box>
          <Box
            m="auto"
            w={["100%", "100%", "100%", "60%", "60%", "60%"]}
            mt="30px"
          >
            <Heading textAlign="center" size="sm" mt="20px" mb="20px">
              Advanced Cadiovascular Life Support (ACLS) Day 1
            </Heading>
            <SelectInput
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              options={titleData}
              label="Title"
            />
            <FormInput
              onChange={(e) => setFirstname(e.target.value)}
              value={firstname}
              label="First Name"
            />
            <FormInput
              onChange={(e) => setLastname(e.target.value)}
              value={lastname}
              label="Last Name"
            />

            <FormInput
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              label="Email"
            />
            <FormInput
              onChange={(e) => setdiscipline(e.target.value)}
              value={discipline}
              label="Discipline"
            />
            <FormInput
              onChange={(e) => setorganisation(e.target.value)}
              value={organisation}
              label="Organisation"
            />

            <Text>Mobile Number</Text>
            <PhoneInput
              defaultCountry="NG"
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
            />

            <Button
              w="100%"
              mt="30px"
              variant="primary"
              type="submit"
              isDisabled={submitLoader}
            >
              {submitLoader ? (
                <Spinner color="white" thickness="4px" size="lg" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default Events;
