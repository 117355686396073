import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Radio,
  RadioGroup,
  Spinner,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { SelectInput } from "../../components/inputs";
import "react-phone-number-input/style.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PatientLayout from "../../components/layout/patientLayout";
import { useEffect, useState } from "react";
import {
  surgeryRegistration,
  getSugeryType,
  getPatientEdit,
  patientUpdateSurgery,
  getPatientConsultationDetails,
} from "../../utils/fetch";
import { useMutation, useQuery } from "react-query";
import ConsultationComponent from "../../components/consultation/consultationComponent2";

function NewAddPatientSurgery2() {
  let { id } = useParams();
  const navgate = useNavigate();
  const toast = useToast();
  const { data: sugeryData } = useQuery("get-sugeryData", getSugeryType);
  const [searchParams] = useSearchParams();

  const { data, isLoading: loads } = useQuery(
    ["get-consultation-details", id],
    () => getPatientConsultationDetails(id)
  );
  console.log(sugeryData);
  const [cbcPic, setCbcPic] = useState(null);
  const [exit, setExit] = useState(false);
  const [cmpPic, setCmpPic] = useState(null);
  const [ecgPic, setEcgPic] = useState(null);
  const [inrPic, setInrPic] = useState(null);
  const [pttPic, setPttPic] = useState(null);
  const [xrayPic, setXrayPic] = useState(null);
  const [ptPic, setPtPic] = useState(null);
  const [concentFile, setConcentfile] = useState(null);
  const [question3, setQuestion3] = useState("No");
  const [surgery, setSurgery] = useState("");
  const [question2, setQuestion2] = useState("No");
  const [question1, setQuestion1] = useState("No");

  const routeApi = id ? patientUpdateSurgery : surgeryRegistration;
  const { mutate, isLoading } = useMutation(surgeryRegistration, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: "Action Successful",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        if (exit) {
          navgate(`/`);
        }
      } else if (data?.data?.status === 401) {
        toast({
          position: "top-right",
          description: `Please login`,
          status: "error",
          duration: 9000,
        });
        navgate("/");
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (cbcPic) {
      for (let i = 0; i < cbcPic?.length; i++) {
        formData.append("picture_cbc", cbcPic[i]);
      }
    }
    if (cmpPic) {
      for (let i = 0; i < cmpPic?.length; i++) {
        formData.append("picture2", cmpPic[i]);
      }
    }
    if (ecgPic) {
      for (let i = 0; i < ecgPic?.length; i++) {
        formData.append("picture_ecg", ecgPic[i]);
      }
    }
    if (inrPic) {
      for (let i = 0; i < inrPic?.length; i++) {
        formData.append("picture_inr", inrPic[i]);
      }
    }
    if (ptPic) {
      for (let i = 0; i < ptPic?.length; i++) {
        formData.append("picture_pt", ptPic[i]);
      }
    }
    if (pttPic) {
      for (let i = 0; i < pttPic?.length; i++) {
        formData.append("picture_ptt", pttPic[i]);
      }
    }
    if (xrayPic) {
      for (let i = 0; i < xrayPic?.length; i++) {
        formData.append("picture_xray", xrayPic[i]);
      }
    }
    if (concentFile) {
      formData.append("picture", concentFile);
    }
    console.log(surgery);

    formData.append("surgery_type_id", surgery);
    formData.append("question_outcome", question3);
    formData.append("question_challenges", question1);
    formData.append("question_med_history", question2);
    const mainInfo = {
      id: id,
      data: formData,
    };
    mutate(mainInfo);
  };

  return (
    <Box
      bg="white"
      borderRadius="20px"
      m="auto"
      mt="30px"
      p={["0px", "0px", "20px", "20px", "20px", "20px"]}
    >
      <form onSubmit={submitHandler}>
        <Heading mb="10px" size="md">
          Surgery Information
        </Heading>
        <Flex>
          <Box w="100%" mr="30px">
            <SelectInput
              options={sugeryData?.data?.result}
              onChange={(e) => setSurgery(e.target.value)}
              isRequired={true}
              value={surgery}
              label="Surgery type"
            />
          </Box>
        </Flex>

        <Flex mt="60px">
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload consent form
            </Text>

            {concentFile ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setConcentfile(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setConcentfile(event.target.files[0])}
              />
            )}
          </Box>
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab CBC
            </Text>

            {cbcPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setCbcPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setCbcPic(event.target.files)}
                multiple
              />
            )}
          </Box>
        </Flex>
        {/* <Flex mt="60px">
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab CMP
            </Text>

            {cmpPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setCmpPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setCmpPic(event.target.files)}
                multiple
              />
            )}
          </Box>
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab ECG
            </Text>

            {ecgPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setEcgPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setEcgPic(event.target.files[0])}
                multiple
              />
            )}
          </Box>
        </Flex>
        <Flex mt="60px">
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab INR
            </Text>

            {inrPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setInrPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setInrPic(event.target.files)}
                multiple
              />
            )}
          </Box>
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab PTT
            </Text>

            {pttPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setPttPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setPttPic(event.target.files[0])}
                multiple
              />
            )}
          </Box>
        </Flex>
        <Flex mt="60px">
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab Xray
            </Text>

            {xrayPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setXrayPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setXrayPic(event.target.files)}
                multiple
              />
            )}
          </Box>
          <Box w="50%">
            <Text mb="10px" size="sm">
              Upload lab PT
            </Text>

            {ptPic ? (
              <Button
                colorScheme="red"
                mt="10px"
                size="sm"
                onClick={() => {
                  setPtPic(null);
                }}
              >
                Remove File
              </Button>
            ) : (
              <input
                type="file"
                name="picture"
                onChange={(event) => setPtPic(event.target.files[0])}
                multiple
              />
            )}
          </Box>
        </Flex> */}

        <Flex mt="60px">
          <Box w="50%">
            <Text mb="10px" size="sm">
              Do you understand the expected outcome of the surgery and the
              recovery process?
            </Text>
            <RadioGroup onChange={setQuestion1} value={question1}>
              <Box mb="15px" fontFamily="10px" mt="10px">
                <Radio isRequired={true} mr="20px" value="Yes">
                  Yes
                </Radio>
                <Radio isRequired={true} value="No">
                  No
                </Radio>
              </Box>
            </RadioGroup>
          </Box>
          <Box w="50%">
            <Text mb="10px" size="sm">
              Have you disclosed all relevant medical history, including
              allergies and medications?
            </Text>
            <RadioGroup onChange={setQuestion2} value={question2}>
              <Box mb="15px" fontFamily="10px" mt="10px">
                <Radio isRequired={true} mr="20px" value="Yes">
                  Yes
                </Radio>
                <Radio isRequired={true} value="No">
                  No
                </Radio>
              </Box>
            </RadioGroup>
          </Box>
        </Flex>
        <Box w="50%">
          <Text mb="10px" size="sm">
            Are you mentally and emotionally prepared for the challenges that
            may arise during the post-operative period?
          </Text>
          <RadioGroup onChange={setQuestion3} value={question3}>
            <Box mb="15px" fontFamily="10px" mt="10px">
              <Radio isRequired={true} mr="20px" value="Yes">
                Yes
              </Radio>
              <Radio isRequired={true} value="No">
                No
              </Radio>
            </Box>
          </RadioGroup>
        </Box>
        <Flex justifyContent="right">
          <Button
            variant="trans"
            mt="20px"
            type="submit"
            isDisabled={isLoading}
          >
            {isLoading ? (
              <Spinner color="white" thickness="4px" size="lg" />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            onClick={() => setExit(true)}
            variant="primary"
            m="20px"
            type="submit"
            isDisabled={isLoading}
          >
            {isLoading ? (
              <Spinner color="white" thickness="4px" size="lg" />
            ) : (
              "Save & Exit"
            )}
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

export default NewAddPatientSurgery2;
