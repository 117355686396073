import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { addNewVitials } from "../../utils/fetch";
import { useMutation } from "react-query";
import "react-phone-number-input/style.css";
import { useNavigate, useParams } from "react-router-dom";

import { FormInputVitials, SelectInput } from "../../components/inputs";
import { useState } from "react";
import {
  bloodpressureData,
  heightData,
  painData,
  pulseData,
  pulseOximeterData,
  temperatureData,
} from "../../components/data";
function AddVitials() {
  let { id } = useParams();
  const toast = useToast();
  const navgate = useNavigate();

  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [height2, setHeight2] = useState("m");
  const [result, setResult] = useState("");
  const [pulse, setPulse] = useState("");
  const [pulse2, setPulse2] = useState("");
  const [blood_pressureSy, setBlood_pressureSy] = useState("");
  const [blood_pressuredy, setBlood_pressureDy] = useState("");
  const [blood_pressuredy2, setBlood_pressureDy2] = useState("");
  const [temperature, setTemperature] = useState("");
  const [temperature2, setTemperature2] = useState("");
  const [pulseOx, setPulseOx] = useState("");
  const [pulseOx2, setPulseOx2] = useState("");
  const [heightBmi, setHeightBmi] = useState("");
  const [weightBmi, setWeightBmi] = useState("");
  const [respiratory_rate, setRespiratory_rate] = useState("");

  const { mutate, isLoading: isLoadings } = useMutation(addNewVitials, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: "Vitals Added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setWeight("");
        setHeight("");
        setPulse("");
        setBlood_pressureSy("");
        setBlood_pressureDy("");
        setBlood_pressureDy2("");
        setRespiratory_rate("");
        setPulseOx("");
        setTemperature("");
      } else if (data?.data?.status === 401) {
        toast({
          position: "top-right",
          description: `Please login`,
          status: "error",
          duration: 9000,
        });
        navgate("/");
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });
  const newHight = height2 === "cm" ? height / 100 : height;
  const bmi = weight && height ? Math.round(weight / (newHight * 2)) : "";

  const subbmitHandler = (e) => {
    e.preventDefault();
    const data = {
      id: id,
      height: `${height}${height2}`,
      weight: `${weight}kg`,
      pulse: `${pulse}bpm`,
      blood_pressure: blood_pressureSy,
      blood_pressure2: blood_pressuredy,
      temperature: `${temperature}c`,
      respiratory_rate: `${respiratory_rate}bpm`,
      blood_pressure_note: blood_pressuredy2,
      pulse_note: pulse2,
      temperature_note: temperature2,
      pulse_oximeter: pulseOx,
      pulse_oximeter_note: pulseOx2,
    };
    console.log(data);
    mutate(data);
  };

  return (
    <>
      <Box w="100%" p="30px" pr="0px" bg="white" borderRadius="20px" mt="30px">
        <Heading size="sm" mb="20px">
          Add Vitals
        </Heading>
        <form onSubmit={subbmitHandler}>
          <FormInputVitials
            onChange={(e) => setHeight(e.target.value)}
            onChange2={(e) => setHeight2(e.target.value)}
            value={height}
            label="Height"
            name={height2}
            options={heightData}
          />
          <FormInputVitials
            onChange={(e) => setWeight(e.target.value)}
            value={weight}
            label="Weight"
            name="kg"
          />{" "}
          {bmi && (
            <Flex mt="-10px" mr="60px" justifyContent="right">
              BMI: {bmi}
            </Flex>
          )}
          <FormInputVitials
            onChange={(e) => setBlood_pressureSy(e.target.value)}
            value={blood_pressureSy}
            label="Blood Pressure SY"
            name="sy"
          />
          <FormInputVitials
            onChange={(e) => setBlood_pressureDy(e.target.value)}
            onChange2={(e) => setBlood_pressureDy2(e.target.value)}
            value={blood_pressuredy}
            label="Blood Pressure DY"
            name="dy"
            options={bloodpressureData}
          />
          <FormInputVitials
            type="number"
            onChange={(e) => setPulse(e.target.value)}
            onChange2={(e) => setPulse2(e.target.value)}
            value={pulse}
            label="Pulse"
            name="bpm"
            options={pulseData}
          />
          <FormInputVitials
            type="number"
            onChange={(e) => setTemperature(e.target.value)}
            onChange2={(e) => setTemperature2(e.target.value)}
            value={temperature}
            label="Temperature"
            name="c"
            options={temperatureData}
          />
          <FormInputVitials
            type="number"
            onChange={(e) => setRespiratory_rate(e.target.value)}
            value={respiratory_rate}
            label="Respiratory Rate"
            name="bpm"
          />
          <FormInputVitials
            type="number"
            onChange={(e) => setPulseOx(e.target.value)}
            onChange2={(e) => setPulseOx2(e.target.value)}
            value={pulseOx}
            label="Pulse Oxmeter"
            name="SpO2"
            options={pulseOximeterData}
          />
          <Flex justifyContent="right" pr="30px">
            <Button
              variant="primary"
              mt="20px"
              type="submit"
              isDisabled={isLoadings}
            >
              {isLoadings ? (
                <Spinner color="white" thickness="4px" size="lg" />
              ) : (
                "Save"
              )}
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
}

export default AddVitials;
