import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face{font-family:lato;font-style:normal;font-weight:400;src:local('Lato'),url(https://fonts.cdnfonts.com/s/14882/Lato-Regular.woff) format('woff')}
  
    @font-face{font-family:montserrat;font-style:normal;font-weight:500;src:local('Montserrat'),url(https://fonts.cdnfonts.com/s/14883/Montserrat-Medium.woff) format('woff')}    
   `}
  />
);

export default Fonts;
