import axios from "axios";
export const BaseURL = process.env.REACT_APP_API_KEY;
axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [BaseURL];
    const token = JSON.parse(localStorage.getItem("token"));
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
