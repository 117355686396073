import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./pagenation";
import { FormInput } from "./inputs";
import { Spinner } from "react-bootstrap";
import { addTestResult } from "../utils/fetch";
import { useMutation } from "react-query";

function AttendacneTable({ data, isLoading, isError, pagenations }) {
  const navgate = useNavigate();
  const toast = useToast();

  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage] = useState(15);
  const [itemOffset, setItemOffset] = useState(0);
  const [ward, setWard] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  console.log(pagenations);
  const { mutate, isLoading: subbmitLoader } = useMutation(addTestResult, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status === 200) {
        toast({
          position: "top-right",
          description: `Test result Successfully added`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        // window.location.reload();
      } else if (data?.data?.status === 401) {
        toast({
          position: "top-right",
          description: `Please Login`,
          status: "error",
          duration: 9000,
        });
        navgate("/");
      } else {
        toast({
          position: "top-right",
          description: `${data?.data?.error}`,
          status: "error",
          duration: 9000,
        });
      }
    },
    onError: () => {
      toast({
        position: "top-right",
        description: `Something went wrong`,
        status: "error",
        duration: 9000,
      });
    },
  });
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;

    setItemOffset(newOffset);
  };
  const handleSubmit = (item) => {
    const sendData = {
      id: item,
      ward: ward,
    };
    // console.log(sendData);
    mutate(sendData);
  };

  return (
    <Box>
      <TableContainer mt="20px">
        <Table size="sm">
          <Thead bg="#E9E9E9">
            <Tr>
              <Th>Date</Th>
              <Th>Name</Th>
              <Th>email </Th>
              <Th>phone </Th>
              <Th>discipline </Th>
              <Th>organisation</Th>
              <Th>topic</Th>
            </Tr>
          </Thead>
          {isLoading || isError ? (
            <Tbody>
              {[9, 8, 9, 9, 0]?.map((itemds) => (
                <Tr>
                  {[9, 8, 9, 9, 0]?.map((itemds) => (
                    <Td>
                      <Skeleton h="30px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              {currentItems &&
                currentItems?.map((item) => (
                  <Tr bg="white" onClick={() => navgate("")}>
                    <Td>{item?.date}</Td>
                    <Td>
                      {item?.title} {item?.firstname} {item?.lastname}
                    </Td>
                    <Td>{item?.email}</Td>
                    <Td>{item?.phone}</Td>
                    <Td>{item?.discipline}</Td>
                    <Td>{item?.organisation}</Td>
                    <Td>{item?.topic}</Td>
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
        {data?.length <= 0 && (
          <Heading mt="150px" textAlign="center">
            No Events Registration for This Day
          </Heading>
        )}
      </TableContainer>
      <Box position="absolute" bottom="0" right="10">
        <Pagination
          pageCount={isNaN(pageCount) ? 0 : pageCount}
          handlePageClick={handlePageClick}
        />
      </Box>
    </Box>
  );
}

export default AttendacneTable;
