import { lazy } from "react";

export const Login = lazy(() => import("./pages/auth/login"));
export const ForgetPassword = lazy(() => import("./pages/auth/forgetPassword"));
export const ForgetPasswordChange = lazy(() =>
  import("./pages/auth/forgetPasswordChange")
);
export const NotFound = lazy(() => import("./pages/notFound"));
export const Dashboard = lazy(() => import("./pages/dashboard"));
export const MissionHome = lazy(() => import("./pages/mission/missionHome"));
export const ObeservationHistory = lazy(() =>
  import("./pages/admission/obeservationHistory")
);
export const ObservationDetails = lazy(() =>
  import("./pages/admission/observationDetails")
);
export const CreateMission = lazy(() =>
  import("./pages/mission/createMission")
);
export const ViewMission = lazy(() => import("./pages/mission/viewMission"));
export const EditMission = lazy(() => import("./pages/mission/editMission"));
export const Users = lazy(() => import("./pages/users/users"));
export const AddUser = lazy(() => import("./pages/users/addUser"));
export const UserDetails = lazy(() => import("./pages/users/userDetails"));
export const AddPatient = lazy(() => import("./pages/patient/addPatient"));
export const Patients = lazy(() => import("./pages/patient/patient"));
export const PatientDetails = lazy(() =>
  import("./pages/patient/patientDetails")
);
export const PatientDetails2 = lazy(() =>
  import("./pages/patient/patientDetails2")
);
export const PatientDetails3 = lazy(() => import("./pages/patient/reAssign"));
export const AddUser1 = lazy(() => import("./pages/users/addUser1"));
export const AddPatient1 = lazy(() => import("./pages/patient/addPatient1"));
export const AddPatient2 = lazy(() => import("./pages/patient/addPatient2"));
export const Task = lazy(() => import("./pages/task"));
export const Feeds = lazy(() => import("./pages/feeds"));
export const FeedsArticle = lazy(() => import("./pages/feeds/feedsArticle"));
export const FeedsPost = lazy(() => import("./pages/feeds/feedsPost"));
export const Report = lazy(() => import("./pages/report"));
export const Referral = lazy(() => import("./pages/report/Referral"));
export const MissionReport = lazy(() => import("./pages/report/missionReport"));
export const Trend = lazy(() => import("./pages/report/trend"));
export const VitalsTreads = lazy(() => import("./pages/task/allTreads"));
export const AnethesiaEvluation = lazy(() =>
  import("./pages/anethesia/evaluation1")
);
export const AnethesiaEvluation2 = lazy(() =>
  import("./pages/anethesia/evaluation2")
);
export const AnethesiaEvluation3 = lazy(() =>
  import("./pages/anethesia/evaluation3")
);
export const PreAnethesiaEvluation1 = lazy(() =>
  import("./pages/anethesia/preevaluation1")
);
export const PreAnethesiaEvluation2 = lazy(() =>
  import("./pages/anethesia/preevaluation2")
);
export const PreAnethesiaEvluation3 = lazy(() =>
  import("./pages/anethesia/preeevaluation3")
);
export const VitalsTreads2 = lazy(() => import("./pages/task/vitals&Treads2"));
export const Lab = lazy(() => import("./pages/report/lab"));
export const MedMissionReport = lazy(() =>
  import("./pages/report/medMissionReport")
);
export const Publication = lazy(() => import("./pages/report/publication"));
export const Profile = lazy(() => import("./pages/profile"));
export const Surgery = lazy(() => import("./pages/surgery"));
export const SurgeryNurse = lazy(() => import("./pages/surgeryNurse"));
export const Endoscopy = lazy(() => import("./pages/endoscopy"));
export const PreSugery = lazy(() => import("./pages/surgery/preSurgery"));
export const SurgeryDischarge = lazy(() => import("./pages/surgery/discharge"));
export const PreSugeryNurse = lazy(() =>
  import("./pages/surgeryNurse/preSurgeryNurse")
);
export const PreEndo = lazy(() => import("./pages/task/preEndo"));
export const SurgeryOperative = lazy(() =>
  import("./pages/surgery/surgeryOperative")
);
export const EndoOperative = lazy(() =>
  import("./pages/endoscopy/endoOperative")
);
export const SurgeryProgress = lazy(() =>
  import("./pages/surgery/surgeryProgress")
);
export const SurgeryMain = lazy(() => import("./pages/surgery/surgeryMain"));
export const EndoMain = lazy(() => import("./pages/endoscopy/endoMain"));
export const SurgeryMain2 = lazy(() => import("./pages/surgery/surgeryMain2"));
export const SurgeryMain3 = lazy(() => import("./pages/surgery/surgeryMain3"));
export const PatientSurgery = lazy(() =>
  import("./pages/surgery/patientSurgery")
);
export const SurgeryDetails = lazy(() =>
  import("./pages/surgery/surgeryDetails")
);
export const HealthEducation = lazy(() => import("./pages/healthEducation"));
export const HealthPost = lazy(() =>
  import("./pages/healthEducation/healthPost")
);
export const Publish = lazy(() => import("./pages/healthEducation/publish"));
export const Schedule = lazy(() => import("./pages/schedule"));
export const AddSchedule = lazy(() => import("./pages/schedule/addSchedule"));
export const DoctorSchedule = lazy(() =>
  import("./pages/schedule/docSchedule")
);
export const UpdatePassword = lazy(() => import("./pages/auth/updatePassword"));
export const EditSchedule = lazy(() => import("./pages/schedule/editSchedule"));
export const NewAddPatient = lazy(() =>
  import("./pages/new-patient/newAddPatient")
);
export const NewAddPatient1 = lazy(() =>
  import("./pages/new-patient/newAddPatient1")
);
export const NewAddPatient2 = lazy(() =>
  import("./pages/new-patient/newAddPatient2")
);
export const NewAddPatient3 = lazy(() =>
  import("./pages/new-patient/newAddPatient3")
);
export const SuccessfullyAccount = lazy(() =>
  import("./pages/new-patient/success")
);
export const SuccessfullyAccountUser = lazy(() =>
  import("./pages/usersOut/success")
);
export const ConsultationHome = lazy(() => import("./pages/consultation"));
export const ConsultationDetails = lazy(() =>
  import("./pages/consultation/consultationDetails")
);
export const LastVist = lazy(() => import("./pages/consultation/lastVist"));
export const LastMsi = lazy(() => import("./pages/consultation/lastMsi"));
export const AddConsultation = lazy(() =>
  import("./pages/consultation/addConsultation")
);
export const EditConsultation = lazy(() =>
  import("./pages/consultation/editConsultation")
);
export const Messages = lazy(() => import("./pages/communication/message"));
export const LastVitials = lazy(() =>
  import("./pages/consultation/lastVitials")
);
export const Dispensing = lazy(() => import("./pages/dispensing"));
export const AddDrugs = lazy(() => import("./pages/inventory/addDrug"));
export const AddUserOut = lazy(() => import("./pages/usersOut/addUser"));
export const AddUserOut1 = lazy(() => import("./pages/usersOut/addUser1"));
export const ConsultationFullDetails = lazy(() =>
  import("./pages/consultation/consultationFullDetails")
);
export const DrugsDetails = lazy(() =>
  import("./pages/dispensing/drugsDetails")
);
export const EditDrugs = lazy(() => import("./pages/inventory/editDrug"));
export const DrugsFullDetails = lazy(() =>
  import("./pages/inventory/drugsFullDetails")
);
export const Inventory = lazy(() => import("./pages/inventory"));
export const PrintDrugsDetails = lazy(() =>
  import("./pages/dispensing/printDrugsDetails")
);
export const EditDispencedDrug = lazy(() =>
  import("./pages/consultation/editDispencedDrug")
);
export const LabPatientDetails = lazy(() => import("./pages/lab/labDetails"));
export const SampleCollected = lazy(() =>
  import("./pages/lab/sampleCollected")
);
export const InputResult = lazy(() => import("./pages/lab/inputResult"));
export const ResultLab = lazy(() => import("./pages/lab/resutLab"));
export const ResultLabDetails = lazy(() =>
  import("./pages/lab/resultLabDetails")
);
export const LabTestHistory = lazy(() =>
  import("./pages/consultation/labHistory")
);
export const MedicationHistory = lazy(() =>
  import("./pages/consultation/medcationHistory")
);
export const AddmissionHome = lazy(() => import("./pages/admission"));
export const AddObeservation = lazy(() =>
  import("./pages/admission/addObeservation")
);
export const NewAddPatientSurgery = lazy(() =>
  import("./pages/new-patient/newAddPatientSurgery")
);
