import {
  Input,
  FormControl,
  Textarea,
  FormErrorMessage,
  Select,
  Text,
  InputRightAddon,
  InputGroup,
  Flex,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import { painData } from "./data";

export const FormInput = ({
  onChange,
  label,
  type,
  mr,
  onInput,
  id,
  isInvalid,
  error,
  value,
  placeholder,
  min,
  max,
  isRequired,
  size,
  autocomplete,
  onBlur,
  onClick,
  innerElement,
  isDisabled,
  onPaste,
}) => {
  return (
    <FormControl textAlign="left" isInvalid={isInvalid} mr={mr} mb="30px">
      {label && (
        <span>
          {label} <br></br>
        </span>
      )}

      <InputGroup>
        {innerElement ? (
          <InputLeftElement pointerEvents="none">
            {innerElement}
          </InputLeftElement>
        ) : (
          ""
        )}

        <Input
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete={autocomplete}
          onPaste={onPaste}
          isDisabled={isDisabled}
          variant="filled"
          onInput={onInput}
          onBlur={onBlur}
          onClick={onClick}
          min={min}
          max={max}
          size={size}
          {...error}
          isRequired={isRequired}
          type={type}
          border="1px solid #1B1D2847"
          w="100%"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </InputGroup>

      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};
export const FormInputVitials = ({
  onChange,
  label,
  type,
  mr,
  name,
  isInvalid,
  error,
  value,
  placeholder,
  min,
  max,
  isRequired,
  size,
  onBlur,
  options,
  onChange2,
}) => {
  return (
    <FormControl mb="30px">
      <Flex justifyContent="space-between">
        <Text fontWeight="900" w="100%">
          {label}:
        </Text>
        <Box mr="30px">
          <InputGroup size="sm" color="gray">
            <Input
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              variant="filled"
              size="sm"
              {...error}
              isRequired={isRequired}
              type={type}
              border="1px solid #E0DEDE"
              w="55px"
              step="any"
              onChange={onChange}
              value={value}
            />
            <InputRightAddon children={name} />
          </InputGroup>
          {options?.length > 1 && (
            <SelectInput
              w="100px"
              mt="-14px"
              mb="-40px"
              size="sm"
              options={options}
              onChange={onChange2}
            />
          )}
        </Box>
      </Flex>

      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};

export const TextareaInput = ({
  label,
  mr,
  id,
  isRequired,
  onChange,
  isInvalid,
  value,
  error,
  placeholder,
  variant,
  h,
  mt,
  ml,
  w,
}) => {
  return (
    <FormControl
      textAlign="left"
      isInvalid={isInvalid}
      mb="30px"
      mt={mt}
      ml={ml}
      w={w}
    >
      <span>{label}</span>
      <br></br>
      <Textarea
        {...error}
        variant={variant}
        isRequired={isRequired}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        w="100%"
        value={value}
        h={h}
      />
      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};
export const SelectInput = ({
  label,
  isInvalid,
  error,
  isRequired,
  options,
  mr,
  onChange,
  placeholders,
  value,
  isDisabled,
  bg,
  w,
  color,
  size,
  mt,
  mb,
}) => {
  return (
    <FormControl textAlign="left" mb="30px" mr={mr} mt={mt}>
      <span>{label}</span>
      <br></br>
      <Select
        variant="filled"
        {...error}
        size={size}
        value={value}
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        w={w}
        bg={bg}
        color={color}
        mb={mb}
      >
        <option value="">{placeholders}</option>
        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </Select>
      <p style={{ color: "#FC8181", fontSize: "14px" }}>{isInvalid}</p>
    </FormControl>
  );
};
export const SelectUsers = ({
  label,
  isInvalid,
  error,
  isRequired,
  options,
  mr,
  onChange,
  placeholders,
  isDisabled,
  bg,
  w,
  color,
}) => {
  return (
    <FormControl textAlign="left" mb="30px" mr={mr}>
      <span>{label}</span>
      <br></br>
      <Select
        {...error}
        size="md"
        variant="filled"
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        w={w}
        bg={bg}
        color={color}
      >
        <option value=""></option>
        {options?.map((option) => (
          <option key={option?.user_id} value={option?.user_id}>
            {option?.name}
          </option>
        ))}
      </Select>
      <p style={{ color: "#FC8181", fontSize: "14px" }}>{isInvalid}</p>
    </FormControl>
  );
};
export const SelectOne = ({
  label,
  isInvalid,
  error,
  isRequired,
  options,
  mr,
  onChange,
  placeholders,
  isDisabled,
  bg,
  w,
  color,
}) => {
  return (
    <FormControl textAlign="left" mb="30px" mr={mr}>
      <span>{label}</span>
      <br></br>
      <Select
        {...error}
        size="md"
        variant="filled"
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        w={w}
        bg={bg}
        color={color}
      >
        <option value=""></option>
        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.value}
          </option>
        ))}
      </Select>
      <p style={{ color: "#FC8181", fontSize: "14px" }}>{isInvalid}</p>
    </FormControl>
  );
};
export const SelectInputMeds = ({
  label,
  options,
  mr,
  onChange,
  value,
  bg,
  w,
  color,
  size,
}) => {
  return (
    <FormControl textAlign="left" fontSize="12px" mb="30px" mr={mr}>
      <span>{label}</span>
      <br></br>
      <Select
        variant="filled"
        size={size}
        value={value}
        onChange={onChange}
        w={w}
        bg={bg}
        color={color}
        fontSize="12px"
      >
        {options?.map((option) => (
          <option key={option?.id} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
