import { Box, Flex, Heading, Image, Skeleton } from "@chakra-ui/react";
import "react-phone-number-input/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbComponent } from "../breadcrumb";
import { BsFillHeartPulseFill } from "react-icons/bs";
import AddVitials from "../../pages/consultation/addVitials";
function ConsultationComponent({ breadcrumbs, data, isLoading, children }) {
  const datas = data?.data?.patients_info || data?.data?.patient;
  const vital = data?.data?.last_vitals && data?.data?.last_vitals[0];

  return (
    <>
      <Box
        mt={["10px", "10px", "0px", "0px", "0px", "0px"]}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <BreadcrumbComponent breadcrumbs={breadcrumbs} />
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box w={["100%", "100%", "100%", "28%", "28%", "28%"]}>
          <Box>
            <Box
              mt="20px"
              bg="white"
              p={["10px", "10px", "30px", "30px", "30px", "30px"]}
              borderRadius="20px"
            >
              {datas && datas[0]?.picture ? (
                <Image
                  alt="preview"
                  src={datas[0]?.picture}
                  maxW="150px"
                  minH="150px"
                  mr="10px"
                  ml="10px"
                />
              ) : (
                <Image
                  alt="preview"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png"
                  minH="150px"
                  maxW="150px"
                  mr="10px"
                  ml="10px"
                  borderRadius="50%"
                />
              )}

              <Flex>
                <Box>
                  <br></br>ID no
                  <br></br> Name
                  <br></br>Gender
                  <br></br> Age
                  <br></br> DOB
                  <br></br>Genotype
                  <br></br> Bloodgroup
                </Box>
                <Box ml="20px" w="80%">
                  {isLoading ? (
                    <>
                      <Skeleton m="10px" mt="25px" h="20px" w="100%" />
                      <Skeleton m="10px" h="20px" w="100%" />
                      <Skeleton m="10px" h="20px" w="100%" />
                      <Skeleton m="10px" h="20px" w="100%" />
                      <Skeleton m="10px" h="20px" w="100%" />
                      <Skeleton m="10px" h="20px" w="100%" />
                    </>
                  ) : (
                    <>
                      <br></br> {datas && datas[0]?.user_id}
                      <br></br>
                      {(datas && datas[0]?.name) ||
                        (datas && datas[0]?.fullname)}
                      <br></br> {datas && datas[0]?.gender}
                      <br></br> {datas && datas[0]?.dob}
                      <br></br> {datas && datas[0]?.age}
                      <br></br> {datas && datas[0]?.genotype}
                      <br></br> {datas && datas[0]?.bloodgroup}
                    </>
                  )}
                </Box>
              </Flex>
            </Box>
          </Box>

          {window.location.pathname.includes(
            "consultation-edit-dispenced-drug"
          ) ? (
            ""
          ) : (
            <>
              <Box
                mt="20px"
                bg="white"
                p={["10px", "10px", "30px", "30px", "30px", "30px"]}
                borderRadius="20px"
              >
                <Heading size="sm" display="flex">
                  <Box mr="20px" mt="2px">
                    <BsFillHeartPulseFill color="#46983A" />
                  </Box>
                  Last vitals
                </Heading>
                <Flex>
                  <Box w="70%">
                    <br></br> Weight
                    <br></br> Height
                    <br></br>Blood pressure
                    <br />
                    <br></br>Pulse
                    <br />
                    <br></br>Temperature
                    <br />
                    <br></br>Respiratory Rate
                    <br />
                    Pulse Oxmeter
                    <br />
                  </Box>
                  <Box ml="20px" w="80%">
                    {isLoading ? (
                      <>
                        <Skeleton m="10px" mt="25px" h="20px" w="100%" />
                        <Skeleton m="10px" h="20px" w="100%" />
                        <Skeleton m="10px" h="20px" w="100%" />
                        <Skeleton m="10px" h="20px" w="100%" />
                        <Skeleton m="10px" h="20px" w="100%" />
                        <Skeleton m="10px" h="20px" w="100%" />
                      </>
                    ) : (
                      <>
                        <br></br> {datas && vital?.weight}
                        <br></br> {datas && vital?.height}
                        <br></br> {datas && vital?.blood_pressure}
                        <br></br> {datas && vital?.blood_pressure_note}
                        <br></br> {datas && vital?.pulse}
                        <br></br> {datas && vital?.pulse_note}
                        <br></br> {datas && vital?.temperature}
                        <br></br> {datas && vital?.temperature_note}
                        <br></br> {datas && vital?.respiratory_rate}
                        <br></br> {datas && vital?.pulse_oximeter}
                        <br></br> {datas && vital?.pulse_oximeter_note}
                      </>
                    )}
                  </Box>
                </Flex>
              </Box>
              <AddVitials />
            </>
          )}
        </Box>
        <Box
          w={["100%", "100%", "100%", "68%", "68%", "68%"]}
          ml={["0px", "0px", "0px", "30px", "30px", "30px"]}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

export default ConsultationComponent;
